import React, { useEffect, useState, useMemo } from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import { Button, Col, Row, List, ListInlineItem } from 'reactstrap';
import { BootstrapTextInput } from '../../../components/forms';
import { buildSmsTemplate } from '../../../utils/sms';
import * as Yup from 'yup';

function countHttps(str) {
  const regex = /https[\S]*/; // regular expression to match "https" followed by any non-whitespace characters
  const match = str.match(regex); // try to find a match in the string

  if (match) {
    const count = match[0].length; // get the length of the matched string
    return count;
  } else {
    return 0;
  }
}

function UpdateSmsContentState({ setSmsContent, setIsValid, setHttpsCounts }) {
  const { values, validateForm, isValid } = useFormikContext();

  useEffect(() => {
    setSmsContent(values);
    Object.keys(values).forEach((key) => {
      setHttpsCounts((prev) => ({
        ...prev,
        [key]: countHttps(values[key]),
      }));
    });
    validateForm();
    // eslint-disable-next-line
  }, [values]);

  useEffect(() => {
    setIsValid(isValid);
    // eslint-disable-next-line
  }, [isValid]);

  return null;
}

/**
 * TODO: Figure this out for Contentful before go live
 */
function getWebPostPermalink(webPosts, webPostConfigs) {
  // webPosts is an object where each key is a webPost response's data object
  // Check if we have any webPosts
  const webPostKeys = Object.keys(webPosts).sort();
  if (webPostKeys.length < 1) {
    return '';
  }
  // Pull the type out of the first webPost
  const firstWebPostKey = webPostKeys[0];
  const { type, ...rest } = webPosts[firstWebPostKey];

  if (type === 'WpConfig') {
    // Get the permalink template and the generated slug
    const permalinkTemplate = rest.data.permalink_template;
    const slug = rest.data.generated_slug;
    // Replace the slug in the permalink template with the actual slug
    const permalink = permalinkTemplate.replace('%postname%', slug);
    return permalink;
  } else if (type === 'ContentfulConfig') {
    // Get our Contentful config
    const config = webPostConfigs.find(
      (config) =>
        config.name.toLowerCase().replace(/\s/g, '-') === firstWebPostKey,
    );
    const { publicationSlug, categorySlug } = config;
    const articleSlug = rest.entry.fields.slug['en-US'];
    return `https://beta.${config.site}.com/my-membership/subscription/${publicationSlug}/${categorySlug}/${articleSlug}`;
  }

  // Default return if type is not recognized
  return '';
}

function Sms({
  setSmsContent,
  setIsValid,
  smsContent,
  smsBroadcasts,
  itemCode,
  options,
  status,
  sendSmsTest,
  launched,
  webPostConfigs,
}) {
  const [internalContent, setInternalContent] = useState({});
  // SMS Template Links
  const [smsTemplateLinks, setSmsTemplateLinks] = useState([]);
  const [isInternalValid, setIsInternalValid] = useState(true);
  const [httpsCounts, setHttpsCounts] = useState({});

  const validationSchema = useMemo(() => {
    if (Object.keys(internalContent).length > 0) {
      let validationSchema = {};
      Object.keys(internalContent).forEach((key) => {
        const fieldName = key;
        const displayName = smsBroadcasts.find(
          (broadcast) =>
            broadcast.config.name.toLowerCase().replace(/\s/g, '-') === key,
        ).config.name;
        validationSchema[fieldName] = Yup.string()
          .max(
            160 +
              httpsCounts[fieldName] -
              (httpsCounts[fieldName] > 0 ? 30 : 0),
            displayName + ' is too long',
          )
          .required(displayName + ' is required');
      });
      return Yup.object().shape(validationSchema);
    } else return Yup.object().shape({});
    // eslint-disable-next-line
  }, [internalContent, httpsCounts]);

  useEffect(() => {
    if (status && status.hasOwnProperty('bsftSmsTemplates')) {
      const statusKeys = Object.keys(status.bsftSmsTemplates);
      if (statusKeys.length > 0) {
        const links = statusKeys.map((key) => ({
          link: `https://app.getblueshift.com/dashboard#/app/sms_template/${status.bsftSmsTemplates[key].uuid}/edit/content`,
          displayName: status.bsftSmsTemplates[key].name.split('|')[1].trim(),
        }));
        setSmsTemplateLinks(links);
        console.log('SMS Template Links', links);
      }
    }
  }, [status]);

  useEffect(() => {
    if (smsBroadcasts && smsBroadcasts.length > 0) {
      let tmpContent = { ...smsContent };
      smsBroadcasts.forEach((broadcast) => {
        let broadcastName = broadcast.config.name
          .toLowerCase()
          .replace(/\s/g, '-');
        if (!smsContent.hasOwnProperty(broadcastName)) {
          let assembledSmsContent = buildSmsTemplate({
            template: broadcast.template.content,
            options,
            fromName: broadcast.config.fromName,
            emailTemplateUuid: status.bsftEmailTemplate?.uuid,
            itemCode,
            webPostPermalink: getWebPostPermalink(
              status.webPosts || {},
              webPostConfigs,
            ),
          });
          tmpContent[broadcastName] = assembledSmsContent;
        }
      });
      setInternalContent({ ...tmpContent });
    }
    // eslint-disable-next-line
  }, [smsBroadcasts]);

  return (
    <Col>
      <Row>
        <Col>
          <p>Changes made here will be reflected in the SMS messages only...</p>
        </Col>
      </Row>
      <Row className="mb-3 d-flex align-items-center">
        {smsTemplateLinks.length > 0 && (
          <Col>
            <List className="mb-0" type="inline">
              <ListInlineItem>SMS Templates:</ListInlineItem>
              {smsTemplateLinks.map((link) => (
                <ListInlineItem key={link.link}>
                  <a href={link.link} target="_blank" rel="noreferrer">
                    {link.displayName}
                  </a>
                </ListInlineItem>
              ))}
            </List>
          </Col>
        )}
        <Col>
          <Button
            onClick={sendSmsTest}
            color="cpblue-500"
            className="float-end"
            disabled={launched || !isInternalValid}
          >
            Send Test SMS
          </Button>
        </Col>
      </Row>
      <Formik
        initialValues={internalContent}
        enableReinitialize={true}
        validationSchema={validationSchema}
        isInitialValid={() => true}
      >
        {() => (
          <Form>
            <Row>
              {Object.keys(internalContent).length > 0 ? (
                <>
                  {smsBroadcasts.map((broadcast) => {
                    let broadcastName = broadcast.config.name
                      .toLowerCase()
                      .replace(/\s/g, '-');
                    return (
                      <BootstrapTextInput
                        key={broadcastName}
                        fullWidth
                        isTextArea
                        maxLength={160}
                        countSubtract={httpsCounts[broadcastName]}
                        countAdd={httpsCounts[broadcastName] > 0 ? 30 : 0}
                        label={broadcast.config.name}
                        id={broadcastName}
                        name={broadcastName}
                        placeholder={broadcast.config.name}
                      />
                    );
                  })}
                </>
              ) : (
                <h3>There is no SMS content to display...</h3>
              )}
            </Row>
            <UpdateSmsContentState
              setSmsContent={setSmsContent}
              setIsValid={(isValid) => {
                setIsInternalValid(isValid);
                setIsValid(isValid);
              }}
              setHttpsCounts={setHttpsCounts}
            />
          </Form>
        )}
      </Formik>
    </Col>
  );
}

export default Sms;
